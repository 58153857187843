(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const $ = require("jquery");

class ProductSlider
{
	constructor() {
		$(document).ready(() => {
			this.autoLoad();
		});
	}

	/**
	 * Instantiates product slides.
	 * @return void
	 */
	autoLoad() {
		$(".product-slider").flexslider({
			slideshow: true,
			animation: "slide",
			animationLoop: false,
			itemWidth: 238,
			itermMargin: 40,
			controlNav: false,
			directionNav: false
		});
	}
}

module.exports = new ProductSlider();

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/product-slider.js","/modules")