(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(window){

	const $ = require('jquery');
	window.jQuery = $;
	window.$ = $;

	const flexslider = require("./vendor/jquery.flexslider-min.js");
	const ga = require('./modules/google-analytics.js');
	const productSlider = require("./modules/product-slider.js");
	const header = require("./modules/header.js");

	ga('UA-XXXXXX-X');

})(window);

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_e2128603.js","/")