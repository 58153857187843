(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const $ = require("jquery");

const debounce = (func, wait, immediate) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const resize = debounce(() => {
	if ($(window).width() <= 850)
		return;

	if ($(window).scrollTop() < 50)
		$("body").removeClass("header-floating");
	else
		$("body").addClass("header-floating");
}, 20);

class Header
{
	constructor() {
		this.menuActive = false;

		$(document).ready(() => {
			this.autoLoad();
		});
	}

	toggleMenu() {
		if (this.menuActive) {
			$(this).removeClass("active");
			$("[data-ui-bind=mobile-menu]").removeClass("active");
		} else {
			$(this).addClass("active");
			$("[data-ui-bind=mobile-menu]").addClass("active");
		}

		this.menuActive = ! this.menuActive;
	}

	/**
	 * @return void
	 */
	autoLoad() {
		$(window).on("scroll", resize);
		$("[data-ui-hook=mobile-toggle]").on("click", this.toggleMenu);
		//$("[data-ui-hook=mobile-toggle]").on("click", this.toggleMenu);
	}
}

module.exports = new Header();

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/header.js","/modules")